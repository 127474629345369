/* global module require */
import { applyMiddleware } from 'redux'
import { createInjectSagasStore, sagaMiddleware, reloadSaga } from 'redux-sagas-injector'
import { composeWithDevTools } from 'redux-devtools-extension'
import { appIsInitialised } from '@actions/app'
// import {} from '@types'
import reducer from '@reducers'
import sagas from '@sagas'

export default (history, initialState = {}) => {
  const storeMiddleware = applyMiddleware(sagaMiddleware)
  const composeEnhancers = composeWithDevTools({
    actionsBlacklist: [],
  })
  const store = createInjectSagasStore(
    { sagas },
    reducer,
    initialState,
    composeEnhancers(storeMiddleware)
  )

  store.dispatch(appIsInitialised())

  if (module.hot) {
    module.hot.accept(['@reducers', '@sagas'], () => {
      store.replaceReducer(require('@reducers').default)
      reloadSaga('sagas', require('@sagas').default)
    })
  }
  return store
}
