/* global require */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { isDevelopment, isProduction } from '@helpers'

if (isDevelopment()) {
  require('./index.dev.jsx')
}

if (isProduction()) {
  require('./index.prod.jsx')
}
