import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { render } from 'react-dom'
import App from '@components/App'

import history from '@utils/history'
import configureStore from '@store/configure.dev'

const initialState = {}
const store = configureStore(history, initialState)
const mountNode = document.getElementById('root')

render(<App store={store} />, mountNode)
