// import PropTypes from '@utils/prop-types'
import styles from './Monitors.scss'

const Monitors = () => <div className={styles.base}>Monitors</div>

Monitors.defaultProps = {}
Monitors.propTypes = {}


export default Monitors
