import 'whatwg-fetch'
import normalize from 'json-api-normalizer'
import qs from 'qs'
import config from '@config'

const createUrl = (path, query = {}) => {
  const apiConf = config.get('api')
  const apiBase = `${apiConf.protocol}://${apiConf.host}${apiConf.port ? `:${apiConf.port}` : ''}${
    apiConf.path
  }`

  return `${apiBase}${path}${qs.stringify(query, { addQueryPrefix: true })}`
}

export const call = (url, options, onSuccess = () => {}, onError = () => {}) => {
  const controller = new AbortController()
  options.signal = controller.signal

  options.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization:
      'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hcGkucHJvYm90cy5pb1wvYXV0aFwvbG9naW4iLCJpYXQiOjE2MzczMzI2MTMsImV4cCI6MTY0MDAxMTAxMywibmJmIjoxNjM3MzMyNjEzLCJqdGkiOiJwTHdkZ1RJWktTRmFXa2hNIiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3Iiwic3BlY2lhbCI6eyJ1c2VySWQiOjEsImFjY291bnRJZCI6MSwidXNlclJvbGUiOiJOT1Q6U0VUIn19.TQCzU1w768CpuWf9tiR6XO4bvmrppy6mn1r73Rbb-zM',
  }

  fetch(url, options)
    .then(async response => {
      if (response.ok) {
        const json = await response.json()

        if (json.success) {
          onSuccess(normalize(json?.payload))
        } else {
          onError(json?.payload)
        }
      } else {
        const json = await response.json()
        onError(json)
      }
    })
    .catch(err => {
      onError(err.message)
    })

  return controller
}

export const callPromise = (url, options) =>
  new Promise(resolve => {
    call(
      url,
      options,
      json => {
        resolve(json)
      },
      error => {
        resolve({ success: false, error })
      }
    )
  })

export const getSites = () => [
  createUrl('/site-monitors', { include: 'site,pings:limit(20):order(id|desc)' }),
  {
    method: 'GET',
  },
]
