import dot from 'dot-prop'
import api from './defaults/api'

const defaults = {
  api,
}

const conf = () => {
  const get = key => dot.get(defaults, key)

  return {
    get,
  }
}

export default conf()
