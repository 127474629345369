import { SET_SITES } from '@types'

export const initialState = {}

export default (state = initialState, { type, payload = {} } = {}) => {
  switch (type) {
    case SET_SITES:
      return payload

    default:
      return state
  }
}
