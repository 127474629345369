import { Routes, Route } from 'react-router-dom'
import Dashboard from '@components/Pages/Dashboard'
import Sites from '@components/Pages/Sites'
import Monitors from '@components/Pages/Monitors'
import styles from './Body.scss'

const Body = () => (
  <div className={styles.base}>
    <Routes>
      {/* <Public path="/login" component={Login} /> */}

      <Route index path="/" element={<Dashboard />} />
      <Route exact path="/sites" element={<Sites />} />
      <Route exact path="/monitors" element={<Monitors />} />
    </Routes>
  </div>
)

Body.defaultProps = {}
Body.propTypes = {}

export default Body
