import { Layout, Menu, Space } from 'antd'
import { Link } from 'react-router-dom'
import Text from '@components/Ui/Text'
import styles from './Header.scss'

const Header = () => (
  <Layout.Header className={styles.base}>
    <Space size="large">
      <div className={styles.logoHolder}>
        <Text type="logo" className={styles.logo}>
          probots
        </Text>
        <Text type="logo" className={styles.alt}>
          dashboard
        </Text>
      </div>
      <Menu mode="horizontal" defaultSelectedKeys={['2']}>
        <Menu.Item key="sites">
          <Link to="/sites">Sites</Link>
        </Menu.Item>

        <Menu.Item key="monitors">
          <Link to="/monitors">Monitors</Link>
        </Menu.Item>
      </Menu>
    </Space>
  </Layout.Header>
)

Header.defaultProps = {}
Header.propTypes = {}

export default Header
