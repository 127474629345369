/* global process */
/**
 * Determines if development.
 *
 * @return     {boolean}  True if development, False otherwise.
 */
export const isDevelopment = () => process.env.NODE_ENV !== 'production'
/**
 * Determines if production.
 *
 * @return     {boolean}  True if production, False otherwise.
 */
export const isProduction = () => process.env.NODE_ENV === 'production'
