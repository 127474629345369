import { forwardRef } from 'react'
import classNames from 'classnames'
// import PropTypes from 'prop-types'
import styles from './Text.scss'

const Text = forwardRef(({ as, type, color, children, className, html }, ref) => {
  const Tag = as
  return (
    <Tag
      ref={ref}
      className={classNames(
        styles.base,
        className,
        styles[`type-${type}`],
        styles[`color-${color}`]
      )}
    >
      {html && <div dangerouslySetInnerHTML={{ __html: children }} />}
      {!html && children}
    </Tag>
  )
})

Text.defaultProps = {
  as: 'span',
  type: 'default',
  color: 'inherit',
  className: '',
}
Text.propTypes = {}

export default Text
