// import PropTypes from '@utils/prop-types'
import styles from './Dashboard.scss'

const Dashboard = () => <div className={styles.base}>Dashboard</div>

Dashboard.defaultProps = {}
Dashboard.propTypes = {}


export default Dashboard
