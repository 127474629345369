import {
  APPLICATION_INITIALISED,
  APPLICATION_SET_LOADING,
  APPLICATION_SET_ERROR,
  SET_MONITORS,
  SET_PINGS,
  SET_SITES,
} from '@types'

export const appIsInitialised = () => ({
  type: APPLICATION_INITIALISED,
  payload: {},
})

export const appSetLoading = () => ({
  type: APPLICATION_SET_LOADING,
  payload: {},
})

export const appSetError = (type, props = {}) => ({
  type: APPLICATION_SET_ERROR,
  payload: { type, props },
})

export const setSites = data => ({
  type: SET_SITES,
  payload: data,
})

export const setMonitors = data => ({
  type: SET_MONITORS,
  payload: data,
})

export const setPings = data => ({
  type: SET_PINGS,
  payload: data,
})
