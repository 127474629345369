import { all, takeEvery, put } from 'redux-saga/effects'
import { APPLICATION_INITIALISED } from '@types'
import { callPromise, getSites } from '@api'
import { setSites, setMonitors, setPings } from '@store/actions/app'

const fetchSiteMonitors = function*() {
  const [url, options] = getSites()

  const response = yield callPromise(url, options)

  yield put(setSites(response.site))
  yield put(setMonitors(response.siteMonitor))
  yield put(setPings(response.siteMonitorPing))
}

export function* handleApplicationInitialised() {
  yield fetchSiteMonitors()
}

// eslint-disable-next-line func-names
export default function*() {
  yield all([takeEvery(APPLICATION_INITIALISED, handleApplicationInitialised)])
}
