import produce from 'immer'
import { APPLICATION_INITIALISED } from '@types'

export const initialState = {
  initialised: false,
}

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case APPLICATION_INITIALISED:
      return produce(state, s => {
        s.initialised = true
      })

    default:
      return state
  }
}
