import { applyMiddleware, createStore, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { appIsInitialised } from '@actions/app'
import reducer from '@reducers'
import saga from '@sagas'

export default (history, initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware()
  const storeMiddleware = applyMiddleware(sagaMiddleware)
  const store = createStore(reducer, initialState, compose(storeMiddleware))

  sagaMiddleware.run(saga)

  store.dispatch(appIsInitialised())

  return store
}
