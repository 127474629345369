import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { BrowserRouter as Router } from 'react-router-dom'
import { isDevelopment } from '@helpers'
import Header from '@components/Layout/Header'
import Body from '@components/Layout/Body'
import Footer from '@components/Layout/Footer'
// import Debug from '@components/Modules/Debug'

import '@styles/antd/theme.less'
import '@fonts/index.scss'
import '@styles/index.scss'

const App = ({ store }) => (
  <Provider store={store}>
    <Router>
      <Header />
      <Body />
      <Footer />
      {/* <Debug /> */}
    </Router>
  </Provider>
)

App.defaultProps = {}

App.propTypes = {
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
    liftedStore: PropTypes.shape.isRequired,
    replaceReducer: PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
  }).isRequired,
}

export default (isDevelopment() ? hot(App, {}) : App)
