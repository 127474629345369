// import PropTypes from '@utils/prop-types'
import styles from './Sites.scss'

const Sites = () => <div className={styles.base}>Sites</div>

Sites.defaultProps = {}
Sites.propTypes = {}


export default Sites
