import { Layout, Menu, Space } from 'antd'
import styles from './Footer.scss'

const Footer = () => (
  <div className={styles.base}>
    <Layout.Footer style={{ textAlign: 'center' }}>made with ❤️ by probots</Layout.Footer>
  </div>
)

Footer.defaultProps = {}
Footer.propTypes = {}

export default Footer
